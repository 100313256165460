import Vue from 'vue'
import Vuex from 'vuex'
import _store from '../commons/_store'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  namespaced: true,
  state: Object.assign(_store.state, {
    gameIndex: null,
    markerLayer: {successes: null, errors: null},
    isTargetVisible: false,
    targetPosition: {top: 0, left: 0},
    newShot: null,
    isMarkerClicked: false,
    editType: null, // null, 'edit', 'add', 'new'
    visibleResults: ['win', 'err', 'cont'],
    editLabelDialog: false,
    editShotIndex: null,
    confirmDialog: false,
  }),
  mutations: Object.assign(_store.mutations, {
		setGameIndex(state, num) {
			state.gameIndex = num
		},
		setMarkerLayer(state, obj) {
			state.markerLayer = obj
		},
		setIsTargetVisible(state, bool) {
			state.isTargetVisible = bool
		},
		setTargetPosition(state, obj) {
			state.targetPosition = obj
		},
		setNewShot(state, obj) {
			state.newShot = obj
		},
		setIsMarkerClicked(state, bool) {
			state.isMarkerClicked = bool
		},
    setEditType(state, str) {
      state.editType = str
    },
    setVisibleResults(state, arr) {
      state.visibleResults = arr
    },
    setEditLabelDialog(state, bool) {
      state.editLabelDialog = bool
    },
    setEditShotIndex(state, num) {
      state.editShotIndex = num
    },
    setConfirmDialog(state, bool) {
      state.confirmDialog = bool
    },
  }),
})
