<template>
  <fragment>

    <div class="container"
      :style="{height: bodyHeight - 106 + 'px'}"
    >
      <DataTable />
      <Map />
    </div>
    
    <EditLabelDialog />
    <EditShotDialog />
  </fragment>
</template>

<script>
  import DataTable from './Tab0DataTable.vue';
  import Map from './Tab0Map.vue';
  import EditLabelDialog from './Tab0EditLabelDialog.vue';
  import EditShotDialog from './Tab0EditShotDialog.vue';
  export default {
    data() {
      return {
        test: false
      };
    },
    computed: {},
		watch: {},
    mounted() {},
    methods: {},
    components: { DataTable, Map, EditLabelDialog, EditShotDialog },
    
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
</style>
