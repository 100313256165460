
const VER = 1.0;

export default Object.freeze({
	APP_ID: 'shots',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: '',
		isAdmin: false,
		data: {
      games: [],
      theme: 'system', // 'system', 'dark', 'light'
    },
	},
	URLS: {
		apis: ['https://api1.aroundit.net', 'https://api2.aroundit.net'],
		site: 'https://apps.wanakijiji.com',
    pwa: '',
		play: '',
		app: '',
	},
	API_KEYS: {
		admob: {
			android: 'ca-app-pub-3940256099942544/6300978111', // デモID
			ios: 'ca-app-pub-3940256099942544/2934735716', // デモID
		}
	},
  FIREBASE: {
    apiKey: "AIzaSyB4alarXaKAs5OTKJcAN2M8AfIC_fgKtK0",
    authDomain: "shots-2021.firebaseapp.com",
    projectId: "shots-2021",
    storageBucket: "shots-2021.appspot.com",
    messagingSenderId: "638315502265",
    appId: "1:638315502265:web:da8187b24509eb5ee95968",
    measurementId: "G-TSHKEJ5C3K"
  },
	POST_ID: 574,
  SOURCE: {
    name: {
      ja: '',
      en: '',
    },
    url: {
      ja: '',
      en: '',
    },
  },
  THEMES: [
    {id: 'light', label: {ja: 'ライト', en: 'Light'}},
    {id: 'dark', label: {ja: 'ダーク', en: 'Dark'}},
    {id: 'system', label: {ja: 'システムデフォルト', en: 'System Default'}}
  ],
  // 表示メニュー一覧
  MENU: {
    order: ['home', 'setting', 'request', 'contact', 'review'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [{
      id: 'setting',
      icon: 'mdi-cog',
      to: '/setting',
      title: {ja: '設定', en: 'Setting'},
    }], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#388E3C',
      primaryBG: '#388E3C',
      secondary: '#F9A825',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#0288D1', // added
      win: '#1565C0', // added
      err: '#D84315', // added
      cont: '#455A64',
    },
    dark: {
      primary: '#81C784',
      primaryBG: '#388E3C', // 暗めに設定
      secondary: '#F57F17', // 暗めに設定
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#004D40', // 暗めに設定
      win: '#1565C0', // added
      err: '#D84315', // added
      cont: '#455A64',
    }
  },
  SHOT_TYPES: [
    {angle: 360, type: 'FS', label: {ja: 'フォアハンドストローク', en: 'Forehand Stroke'}, color: '#3366cc'},
    {angle: 240, type: 'BS', label: {ja: 'バックハンドストローク', en: 'Backhand Stroke'}, color: '#dc3912'},
    {angle: 60, type: 'FV', label: {ja: 'フォアハンドボレー', en: 'Forehand Volley'}, color: '#ff9900'},
    {angle: 180, type: 'BV', label: {ja: 'バックハンドボレー', en: 'Backhand Volley'}, color: '#109618'},
    {angle: 120, type: 'SM', label: {ja: 'スマッシュ', en: 'Forehand Smash'}, color: '#990099'},
    {angle: 300, type: 'FO', label: {ja: 'フォースド', en: 'Forced'}, color: '#0099c6'},
  ],
  SHOT_RESULTS: [
    {name: 'win', label: {ja: 'Winner', en: 'Winner'}},
    {name: 'err', label: {ja: 'Error', en: 'Error'}},
    {name: 'cont', label: {ja: 'Continue', en: 'Continue'}},
  ],
  ZOOMS: [
    {height: 300, value: 8.2},
    {height: 380, value: 8.4},
    {height: 520, value: 8.6}, // for iphoneX
    {height: 600, value: 8.8},
    {height: 720, value: 9.2},
    {height: 840, value: 9.4},
    {height: 10000, value: 9.6}
  ],
})
