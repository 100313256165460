<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-if="dialog"
  >
    <v-app-bar
      dark
      fixed
      scroll-off-screen
      :color="$vuetify.theme.isDark ? '' : 'primary'"
      :height="appBarHeight"
    >
      <v-btn
        icon
        v-show="editType ? false : true"
        @click="gameIndex = null"
      ><v-icon>mdi-close</v-icon></v-btn>
      <v-toolbar-title
        v-if="! editType"
      >{{ game.label }}<span>{{ dayjs(game.timestamp).format('YYYY/MM/DD') }}</span></v-toolbar-title>
      <v-toolbar-title
        v-else
        @click="editLabelDialog = true"
      >{{ game.label }}<span>{{ dayjs(game.timestamp).format('YYYY/MM/DD') }}</span></v-toolbar-title>
      <v-spacer />
      <v-btn
        text
        :color="isThemeDark ? 'primary' : 'white'"
        v-if="editType === 'new'"
        @click="confirmDialog=true"
      >{{ $t('dialog.no') }}</v-btn>
      <v-btn
        text
        :color="isThemeDark ? 'primary' : 'white'"
        v-if="editType"
        @click="editType = null"
      >{{ editType === 'new' ? '保存' : '完了' }}</v-btn>
      <SpeedDial />
    </v-app-bar>

    <v-tabs-items
      v-model="tabId"
      :class="tabId + '-active'"
      touchless
    >
      <v-tab-item value="tab0">
        <Tab0 />
      </v-tab-item>
      <v-tab-item value="tab1">
        <Tab1 />
      </v-tab-item>
      <v-tab-item value="tab2">
        <Tab2 />
      </v-tab-item>
    </v-tabs-items>

    <v-footer
      fixed
      padless
    >
      <v-tabs
        v-model="tabId"
        :background-color="isThemeDark ? '' : 'primary'"
        icons-and-text
        grow
        dark
        height="50px"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(tabItem, index) in tabItems"
          :key="index"
          :href="'#tab' + index"
          :disabled="editType ? true : false"
        >
          {{ tabItem.title[lang] }}
          <v-icon>{{ tabItem.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </v-footer>

  </v-dialog>
</template>

<script>
  import Tab0 from './Tab0.vue';
  import Tab1 from './Tab1.vue';
  import Tab2 from './Tab2.vue';
  import SpeedDial from './GameEditSpeedDial.vue';

  export default {
    data() {
      return {
        tabItems: [{
          id: 'tab0',
          title: {
            ja: 'ショット',
            en: 'shots',
          },
          icon: 'mdi-map-marker',
        }, {
          id: 'tab1',
          title: {
            ja: 'データ',
            en: 'data',
          },
          icon: 'mdi-view-list-outline',
        }, {
          id: 'tab2',
          title: {
            ja: 'グラフ',
            en: 'chart',
          },
          icon: 'mdi-chart-bar',
        }],
      };
    },
    computed: {
      dialog() {
        return (this.game) ? true : false
      },
    },
    watch: {
      tabId() {
        setTimeout(() => {
          const dialog = document.querySelector('.v-dialog--fullscreen');
          if (dialog) {
            dialog.scrollTop = 0
          }
        }, 0)
      },
    },
    components: { Tab0, Tab1, Tab2, SpeedDial },
  };
</script>

<style lang="scss" scoped>
  /* appbar高さ対応 */
  .v-app-bar {
    z-index: 5;
    // ::v-deep .v-toolbar__content {
    //   padding-top: env(safe-area-inset-top);
    // }
  }
  // タイトル＆日付
  .v-toolbar__title {
    line-height: 1.2;
    span {
      display: block;
      font-size: .8em;
      opacity: .6;
    }
  }
  .v-btn {
    font-size: 1rem;
  }
  .v-tabs-items {
    padding-top: 56px;
    &:not(.tab0-active) {
      padding-bottom: 50px;
    }
  }
  .tab0-active {
    overflow: visible; // map targetの飛び出し対策
    z-index: 4; // map targetの飛び出し対策
  }
</style>
