<template>
  <fragment>
    
    <TheAppbar :title="$t('title')"></TheAppbar>
    
    <v-main>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>テーマ</v-list-item-title>
            <v-radio-group v-model="theme">
              <v-radio
                v-for="themeOption in themeOptions"
                :key="themeOption.id"
                :label="themeOption.label[lang]"
                :value="themeOption.id"
              ></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-main>

  </fragment>
</template>

<script>
  import INIT from '../init.js'
  import TheAppbar from '../commons/components/TheAppbar.vue'
  
  export default {
    data() {
      return {
        themeOptions: INIT.THEMES,
      }
    },
    computed: {},
    methods: {},
    i18n: {
      messages: {
        ja: {
          title: '設定',
        },
        en: {
          title: 'Setting',
        }
      }
    },
    components: { TheAppbar },
  }
</script>

<style lang="scss" scoped>
  .v-radio {
    margin: 0 0 14px 20px !important;
  }
</style>
