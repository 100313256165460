<template>
  <fragment>
    <v-speed-dial
      v-model="fab"
      direction="bottom"
      fixed
      v-show="! editType && tabId === 'tab0'"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="transparent"
          dark
          fab
          elevation="0"
          :disabled="editType ? true : false"
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="primaryBG"
        @click="editType = 'edit'"
      >
        <v-icon>mdi-pencil</v-icon>
        <span>編集</span>
      </v-btn>
      <v-btn
        fab
        dark
        color="primaryBG"
        @click="editType = 'add'"
      >
        <v-icon>mdi-plus</v-icon>
        <span>追加</span>
      </v-btn>
      <v-btn
        fab
        dark
        color="red"
        @click="confirmDialog=true"
      >
        <v-icon>mdi-delete</v-icon>
        <span>全削除</span>
      </v-btn>
    </v-speed-dial>

    <ConfirmDialog />
  </fragment>
</template>

<script>
  import ConfirmDialog from './GameConfirmDialog.vue';
  export default {
    data() {
      return {
        fab: false,
      };
    },
    components: { ConfirmDialog },
  };
</script>

<style lang="scss" scoped>
  .v-speed-dial {
    top: 0;
    right: 10px;
    span {
      position: absolute;
      top: 0;
      left: -10px;
      background-color: rgba(0,0,0,.7);
      padding: 7px 10px;
      border-radius: 3px;
      line-height: 1;
      transform: translateX(-100%);
      font-size: 1rem;
    }
  }
</style>

