<template>
  <v-app id="app">
    <AppDrawer />
    <!-- vue-fragment@1.5.1固定 -->
    <router-view />
    <AppNetwork />
  </v-app>
</template>

<script>
  import AppDrawer from './commons/components/AppDrawer.vue';
  import AppNetwork from './commons/components/AppNetwork.vue';

  export default {
    data: () => ({}),
    mounted() {},
    methods: {},
    components: { AppDrawer, AppNetwork },
  };

</script>

<style lang="scss">
  // 100vhを正しく
  // @supports (-webkit-touch-callout: none) {
  //   body {
  //     height: -webkit-fill-available;
  //   }
  // }
  // 大画面時のダイアログサイズ制限
  .v-dialog:not(.v-dialog--fullscreen) {
    max-width: 400px !important;
  }
  // 全画面ダイアログの角丸
  .v-dialog--fullscreen > .v-card {
    border-radius: 0 !important;
  }
  // .v-application--wrap {
  //   min-height: auto !important;
  // }
</style>
