<template>
  <div id="map-container">
    <div id="map"></div>
    <Target />
    <Switchs />
    <Markers />
    <Undo />
  </div>
</template>

<script>
  import INIT from '../init.js'
  import L from 'leaflet'
  import Target from './Tab0MapTarget.vue';
  import Switchs from './Tab0MapSwitchs.vue';
  import Markers from './Tab0MapMarkers.vue';
  import Undo from './Tab0Undo.vue';
  export default {
    data() {
      return {
        map: null,
        shot: null,
        clonedLayer: {successes: null, errors: null},
        clickTimestamp: 0
      };
    },
    computed: {},
		watch: {
      editType() {
        if (this.editType === 'add' || this.editType === 'new') {
          const height = document.querySelector('#map-container').clientHeight
          const zoom = _.find(INIT.ZOOMS, zoom => { return zoom.height > height }).value
          this.map.setZoom(zoom)
        }
      },
      // markers ON/OFF
      visibleResults() {
        if (this.map) {
          _.forEach(INIT.SHOT_RESULTS, shotResult => {
            this.map.removeLayer(this.clonedLayer[shotResult.name])
            if (_.includes(this.visibleResults, shotResult.name)) {
              this.map.addLayer(this.clonedLayer[shotResult.name])
            }
          })
        }
      },
      // add markers
      markerLayer() {
        setTimeout(() => {
          if (this.map) {
            _.forEach(INIT.SHOT_RESULTS, shotResult => {
              if (this.clonedLayer[shotResult.name]) {
                this.map.removeLayer(this.clonedLayer[shotResult.name])
              }
              this.clonedLayer[shotResult.name] = _.cloneDeep(this.markerLayer[shotResult.name])
              this.map.addLayer(this.clonedLayer[shotResult.name])
            })
          }
        }, 50)
      },
		},
    mounted() {
      setTimeout(() => { // 地図が正しく表示されない不具合対策
        // map配置
        const height = document.querySelector('#map-container').clientHeight
        const zoom = _.find(INIT.ZOOMS, zoom => { return zoom.height > height }).value
        this.map = this.drawMap({
          zoom: zoom,
          zoomSnap: 0.1,
          zoomDelta: 0.1,
          wheelPxPerZoomLevel: 200,
          center: {lat: 1, lng: 1},
          minZoom: 8.2,
          maxZoom: 11,
          maxBounds: [[0, 0], [2, 2]],
          zoomControl: false,
        })
        const bgImage = this.isThemeDark ? require('../assets/court-dark.png') : require('../assets/court-light.png')
        L.imageOverlay(bgImage, [[0, 0], [2, 2]]).addTo(this.map)
        this.map.on('click', (e) => {
          if (this.clickTimestamp + 100 > dayjs().valueOf()) { return }
          if (this.editType === 'add' || this.editType === 'new') {
            // marker 新規追加
            this.isTargetVisible = false;
            setTimeout(() => {
              if (! this.isMarkerClicked) {
                this.isTargetVisible = true;
                this.targetPosition = {
                  top: e.containerPoint.y + 'px',
                  left: e.containerPoint.x + 'px',
                };
                this.newShot = {
                  type: null,
                  coord: _.cloneDeep(e.latlng),
                  result: 'win',
                }
              }
            }, 0)
          }
          this.clickTimestamp = dayjs().valueOf()
        })
      }, 0)
    },
    methods: {},
    components: { Target, Switchs, Markers, Undo },
  };
</script>

<style lang="scss" scoped>
  #map-container {
    position: relative;
    z-index: 1;
    flex-grow: 2;
  }
  #map {
    height: 100%;
    z-index: 0;
  }
</style>
