<template>
  <v-container>
    <v-card elevation="0">
      <v-card-subtitle>ウイニングショット</v-card-subtitle>
      <v-card-title>{{ winningShot.label[lang] }}</v-card-title>
      <v-card-actions>{{ winningShot['win'] }}<span class="unit">回</span></v-card-actions>
    </v-card>
    <v-divider />
    <v-card elevation="0">
      <v-card-subtitle>ミスショット</v-card-subtitle>
      <v-card-title>{{ losingShot.label[lang] }}</v-card-title>
      <v-card-actions>{{ losingShot['err'] }}<span class="unit">回</span></v-card-actions>
    </v-card>
    <v-divider />
    <v-card elevation="0">
      <v-card-subtitle>最も安定したショット</v-card-subtitle>
      <v-card-title>{{ stableShot.label[lang] }}</v-card-title>
      <v-card-actions>{{ 100 - stableShot['err %'] }}<span class="unit">%</span></v-card-actions>
    </v-card>
    <v-divider />
    <v-card elevation="0">
      <v-card-subtitle>ミスの多いショット</v-card-subtitle>
      <v-card-title>{{ unstableShot.label[lang] }}</v-card-title>
      <v-card-actions>{{ unstableShot['err %'] }}<span class="unit">%</span></v-card-actions>
    </v-card>
    <v-divider />
    <DataTable :showAll="true" />
  </v-container>
</template>

<script>
  import DataTable from './Tab0DataTable.vue';
  export default {
    data() {
      return {};
    },
    computed: {
      filteredAnalyses() {
        return _.filter(this.analyses, analysis => { return !_.includes(['FO', 'SM'], analysis.name) && analysis.total })
      },
      winningShot() {
        return _.maxBy(this.filteredAnalyses, 'win')
      },
      losingShot() {
        return _.maxBy(this.filteredAnalyses, 'err')
      },
      stableShot() {
        return _.minBy(this.filteredAnalyses, 'err %')
      },
      unstableShot() {
        return _.maxBy(this.filteredAnalyses, 'err %')
      },
    },
    mounted() {},
    methods: {},
    components: { DataTable },
  };
</script>

<style lang="scss" scoped>
  .v-card {
    margin: 5px 0;
    .v-card__subtitle {
      padding: 10px 10px 0;
    }
    .v-card__title {
      padding: 0 10px 10px;
      font-size: 1.1rem;
    }
    .v-card__actions {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 40px;
      .unit {
        font-size: 20px;
        opacity: .7;
        padding: 10px 0 0 5px;
      }
    }
  }
  ::v-deep .v-data-table {
    margin-top: 20px;
    tbody {
      tr:nth-child(4) td {
        height: calc(48px + 20px);
        padding-top: 20px !important;
      }
    }
  }
</style>

