<template>
  <v-dialog
    v-model="confirmDialog"
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>本当に削除しますか？</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="confirmDialog = false"
          color="primary"
        >{{ $t('dialog.no') }}</v-btn>
        <v-btn
          text
          @click="remove()"
          color="warning"
        >{{ $t('dialog.delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    methods: {
      remove() {
        _.pullAt(this.games, [this.gameIndex])
        this.games = _.cloneDeep(this.games)
        this.gameIndex = null
        this.editType = null
        this.confirmDialog = false
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-card__text {
    padding: 0 20px 20px !important;
  }
</style>
