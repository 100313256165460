<template>
  <v-dialog
    v-model="editLabelDialog"
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-text-field
          placeholder="ラベル"
          v-model="label"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-date-picker
          v-model="date"
          locale="ja-jp"
        ></v-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="editLabelDialog = false"
          color="primary"
        >{{ $t('dialog.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {
      label: {
        get() { return this.game.label },
        set(newLabel) { this.game = _.assign(this.game, {label: newLabel}) }
      },
      date: {
        get() { return dayjs(this.game.timestamp).format('YYYY-MM-DD') },
        set(newDate) { this.game = _.assign(this.game, {timestamp: dayjs(newDate).valueOf()}) }
      }
    },
    watch: {
      editLabelDialog() {
        if (this.editLabelDialog) {
          this.removeDate()
        }
      }
    },
    methods: {
      removeDate() {
        setTimeout(() => {
          const dates = document.querySelectorAll('.v-date-picker-table .v-btn__content')
          _.forEach(dates, date => {
            date.innerText = date.innerText.replace('日', '')
          })
        }, 0)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-card__text {
    padding: 0 20px 20px !important;
  }
  .v-picker {
    ::v-deep .v-picker__title {
      display: none;
    }
    ::v-deep .v-picker__body {
      background-color: transparent;
    }
  }
</style>
