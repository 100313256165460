<template>
  <v-list-item @click="gameIndex = index">
    <v-list-item-content>
      <v-card>
        <v-card-title>{{ games[index].label }}</v-card-title>
        <v-card-subtitle>{{ dayjs(games[index].timestamp).format('YYYY-MM-DD') }}</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-sort
            mobile-breakpoint="300"
            dense
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import INIT from '../init.js'
  export default {
    props: ['index'],
    computed: {
      items() {
        const analyses = this.getAnalyses(this.games[this.index].shots)
        const items = this.getTableItem(analyses, 'err %')
        delete items.FO
        return [items]
      },
      headers() {
        const headers = [{
          text: '',
          align: 'center',
          value: 'name'
        }]
        _.forEach(INIT.SHOT_TYPES, shotType => {
          if (shotType.type !== 'FO') {
            headers.push({text: shotType.type, value: shotType.type, align: 'center'})
          }
        })
        return headers
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-data-table {
    ::v-deep td,
    ::v-deep th {
      font-size: 1rem !important;
      padding: 0 !important;
    }
  }
</style>
