<template>
  <div></div>
</template>

<script>
  import L from 'leaflet'
  export default {
    data() {
      return {
        shots: null,
        shot: null,
        index: null,
        marker: null,
        clickTimestamp: 0,
        dragging: false,
      };
    },
		watch: {
      editShotIndex() {
        this.refreshMarkers()
      },
      editType() {
        this.refreshMarkers()
      },
      game: {
        handler() {
          this.refreshMarkers()
        },
        immediate: true
      }
		},
    methods: {
      refreshMarkers() {
        const markerLayer = {win: L.layerGroup(), err: L.layerGroup(), cont: L.layerGroup()}
        this.shots = this.game.shots
        if (this.shots.length) {
          _.forEach(this.shots, (shot, i) => {
            this.shot = shot
            this.index = i
            this.marker = this.getMarker()
            this.addTooltip()
            this.addEvent()
            markerLayer[shot.result].addLayer(this.marker)
          })
        }
        this.markerLayer = markerLayer
      },
      getMarker() {
        // icon size （新規以外の新規は0）
        return L.marker(this.shot.coord, {
                 icon: L.icon({
                   iconUrl: require('../assets/marker.png'),
                   iconSize: ((this.editType === 'add' || this.editType === 'new') && this.index !== this.shots.length - 1) ? [0, 0] : [20, 20], // 直近以外の新規は0
                 }),
                 draggable: (this.editType === 'edit') && this.index === this.editShotIndex
               })
      },
      addTooltip() {
        const classes = this.getClasses()
        this.marker.bindTooltip(this.shot.type, {
          direction: 'center',
          permanent: true,
          opacity: 1,
          className: classes.join(' '),
        })
      },
      getClasses() {
        let classes = []
        classes = ['shot-' + this.index, 'shot-' + this.shot.result]
        if (this.editType === 'add' || this.editType === 'new') {
          classes = _.concat(classes, ['shot-added'])
          classes = (this.index === this.shots.length - 1) ? _.concat(classes, ['shot-active']) : classes
        } else if (this.editType === 'edit') {
          classes = (this.index === this.editShotIndex) ? _.concat(classes, ['shot-active']) : classes
        }
        return classes
      },
      addEvent() {
        const myIndex = this.index
        this.marker.on('click', () => {
          if (this.clickTimestamp + 100 > dayjs().valueOf()) { return }
          this.isMarkerClicked = true
          setTimeout(() => {
            this.isMarkerClicked = false
          }, 100)
          this.editShotIndex = myIndex
          this.clickTimestamp = dayjs().valueOf()
        })
        this.marker.on('dragend', (e) => {
          this.shots[myIndex].coord = [e.target.getLatLng().lat, e.target.getLatLng().lng]
          this.game = _.assign(_.cloneDeep(this.game), {shots: _.cloneDeep(this.shots)})
        })
      },
    },
  };
</script>

<style lang="scss">
  .leaflet-tooltip-pane {
    .leaflet-tooltip {
      border: none;
      font-size: min(4vmin, 18px);
      border-radius: 50%;
      width: min(8vmin, 36px);
      height: min(8vmin, 36px);
      line-height: min(8vmin, 36px);
      text-align: center;
      padding: 0;
      color: #fff;
      background-color: var(--v-cont-base);
      &.shot-added {
        filter: saturate(50%);
      }
      &.shot-win {
        background-color: var(--v-win-base);
      }
      &.shot-err {
        background-color: var(--v-err-base);
      }
      &.shot-active {
        width: min(12vmin, 50px);
        height: min(12vmin, 50px);
        line-height: min(10vmin, 40px);
        border: min(1vmin, 5px) solid #fff;
        background-color: var(--v-cont-base);
        filter: saturate(100%) !important;
        &.shot-win {
          background-color: var(--v-win-base);
        }
        &.shot-err {
          background-color: var(--v-err-base);
        }
      }
    }
  }
</style>
