<template>
  <v-btn
    fab
    dark
    fixed
    bottom
    right
    color="secondary"
    @click="add()"
  ><v-icon dark>mdi-plus</v-icon></v-btn>
</template>

<script>
  export default {
    methods: {
      add() {
        const id = this.games.length ? _.maxBy(this.games, 'id').id + 1 : 0
        this.games = _.concat([{
          id: id,
          label: 'ゲーム' + id,
          timestamp: dayjs().valueOf(),
          shots: []
        }], this.games)
        this.gameIndex = 0
        this.editType = 'new'
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
