<template>
  <div
    id="undo"
    v-if="editType === 'add'"
  >
    <v-btn
      fab
      dark
      large
      color="secondary"
      @click="undo()"
    ><v-icon dark>mdi-undo-variant</v-icon></v-btn>
  </div>
</template>

<script>
  export default {
    methods: {
      undo() {
        this.game = _.assign(_.cloneDeep(this.game), {shots: _.dropRight(this.game.shots)})
      }
    },
  };
</script>

<style lang="scss" scoped>
  #undo {
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 0;
    text-align: center;
    pointer-events: none;
    .v-btn {
      pointer-events: auto;
    }
  }
</style>
