// common store
import INIT from '../init'

export default {
  state: {
    storage: {},
    pageId: 'home',
    drawer: false,
    tabId: 'tab0',
    bodyWidth: window.innerWidth,
    bodyHeight: window.innerHeight,
    appBarHeight: 56,
    scrollTop: 0,
    pullto: 'ready', // null, ready, pulling, loading, done
    isThemeDark: false, // isDarkだとvuetifyとコンフリクト
  },
  mutations: {
    setStorage(state, data) {
      state.storage = data;
      localStorage.setItem(INIT.APP_ID, JSON.stringify(data))
    },
    setPageId(state, id) {
      state.pageId = id;
    },
    setDrawer(state, isOpen) {
      state.drawer = isOpen;
    },
    setTabId(state, tabId) {
      state.tabId = tabId;
    },
    renewSize(state) {
      state.bodyWidth = window.innerWidth;
      state.bodyHeight = window.innerHeight;
    },
    renewScrollTop(state) {
      state.scrollTop = window.pageYOffset;
    },
    setAppBarHeight(state, height) {
      state.appBarHeight = height;
    },
    setPullto(state, status) {
      state.pullto = status;
    },
    setIsThemeDark(state, bool) {
      state.isDark = bool;
    },
  },
}