<template>
  <div class="buttons"
    v-show="isTargetVisible"
    :style="targetPosition"
  >
    <v-btn
      fab
      color="secondary"
      @click="close()"
    ><v-icon>mdi-close</v-icon></v-btn>
    <div v-if="step === 0">
      <v-btn
        v-for="shotType in shotTypes"
        :key="shotType.type"
        :class="['add-btn', 'btn-' + shotType.type]"
        fab
        color="secondary"
        @click="next(shotType.type)"
      >{{ shotType.type }}</v-btn>
    </div>
    <div v-if="step === 1">
      <v-btn
        v-for="shotResult in shotResults"
        :key="shotResult"
        :class="['add-btn', 'btn-' + shotResult]"
        fab
        color="secondary"
        @click="add(shotResult)"
      >{{ shotResult }}</v-btn>
    </div>
  </div>
</template>

<script>
  import INIT from '../init.js'
  export default {
    data() {
      return {
        shotTypes: INIT.SHOT_TYPES,
        shotResults: ['err', 'win', 'cont'],
        step: 0,
      };
    },
    methods: {
      close() {
        this.isTargetVisible = false
        this.step = 0
      },
      next(type) {
        this.newShot = _.assign(_.cloneDeep(this.newShot), {type: type})
        this.step = 1
      },
      add(result) {
        this.newShot = _.assign(_.cloneDeep(this.newShot), {result: result})
        this.game = _.assign(_.cloneDeep(this.game), {shots: _.concat(this.game.shots, [this.newShot])})
        this.isTargetVisible = false
        this.step = 0
      }
    },
    watch: {
      isTargetVisible() {
        this.step = 0
      },
      editType() {
        this.isTargetVisible = false
        this.step = 0
      }
    }
  };
</script>

<style lang="scss" scoped>
  .buttons {
    position: absolute;
    transform: translate(-28px, -28px);
    z-index: 1;
    .v-btn {
      position: absolute;
      font-size: 1.2em;
      border: 3px solid #fff !important;
    }
    .btn-SM {
      top: -70px;
      left: 0;
    }
    .btn-FV {
      top: -35px;
      left: 70px;
    }
    .btn-FS {
      top: 35px;
      left: 70px;
    }
    .btn-FO {
      top: 70px;
      left: 0;
      background-color: var(--v-err-base);
    }
    .btn-BS {
      top: 35px;
      left: -70px;
    }
    .btn-BV {
      top: -35px;
      left: -70px;
    }
    .btn-err {
      top: -35px;
      left: -70px;
      background-color: var(--v-err-base) !important;
    }
    .btn-win {
      top: -35px;
      left: 70px;
      background-color: var(--v-win-base) !important;
    }
    .btn-cont {
      top: 70px;
      left: 0;
      background-color: var(--v-cont-base) !important;
      font-size: 1em;
    }
  }
</style>
