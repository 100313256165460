<template>
  <v-btn-toggle
    id="switchs"
    v-if="! editType"
    v-model="visibleResults"
    multiple
  >
    <v-btn
      v-for="shotResult in shotResults"
      :key="shotResult.name"
      dark
      small
      :color="shotResult.name"
      :value="shotResult.name"
      elevation="2"
      class="shotResult.name"
      active-class="show"
    >{{ shotResult.label[lang] + 'S' }}</v-btn>
  </v-btn-toggle>
</template>

<script>
  import INIT from '../init.js'
  export default {
    data() {
      return {
        shotResults: INIT.SHOT_RESULTS
      };
    },
  };
</script>

<style lang="scss" scoped>
  #switchs {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: transparent;
    .v-btn {
      margin-left: 10px;
      background-color: #fff !important;
      &.win {
        color: var(--v-win-base);
      }
      &.err {
        color: var(--v-err-base);
      }
      &.cont {
        color: var(--v-cont-base);
      }
      &.show {
        color: #fff !important;
        &.win {
          background-color: var(--v-win-base) !important;
        }
        &.err {
          background-color: var(--v-err-base) !important;
        }
        &.cont {
          background-color: var(--v-cont-base) !important;
        }
      }
    }
  }
</style>
