import Vue from 'vue'
import _mixin from '../commons/_mixin';
import INIT from '../init.js'

Vue.mixin({
  data: () => (Object.assign(_mixin.data, {
  })),
  computed: Object.assign(_mixin.computed, {
    games: {
      get() { return this.appData.games },
      set(arr) { this.appData = _.assign(this.appData, {games: arr}) }
    },
    gameIndex: {
      get() { return this.$store.state.gameIndex },
      set(num) {
        this.tabId = 'tab0'
        this.$store.commit('setGameIndex', num)
      }
    },
    game: {
      get() { return ! _.isNull(this.gameIndex) ? _.cloneDeep(this.games[this.gameIndex]) : null },
      set(newGame) {
        if (newGame) {
          this.games[this.gameIndex] = newGame
          this.games = _.cloneDeep(this.games)
        }
      }
    },
    analyses() { return this.getAnalyses(this.game.shots) },
    markerLayer: {
      get() { return this.$store.state.markerLayer },
      set(obj) { this.$store.commit('setMarkerLayer', obj) }
    },
    isTargetVisible: {
      get() { return this.$store.state.isTargetVisible },
      set(bool) { this.$store.commit('setIsTargetVisible', bool) }
    },
    targetPosition: {
      get() { return this.$store.state.targetPosition },
      set(obj) { this.$store.commit('setTargetPosition', obj) }
    },
    newShot: {
      get() { return this.$store.state.newShot },
      set(obj) { this.$store.commit('setNewShot', obj) }
    },
    isMarkerClicked: {
      get() { return this.$store.state.isMarkerClicked },
      set(bool) { this.$store.commit('setIsMarkerClicked', bool) }
    },
    editType: {
      get() { return this.$store.state.editType },
      set(str) { this.$store.commit('setEditType', str) }
    },
    visibleResults: {
      get() { return this.$store.state.visibleResults },
      set(arr) { this.$store.commit('setVisibleResults', arr) }
    },
    editLabelDialog: {
      get() { return this.$store.state.editLabelDialog },
      set(bool) { this.$store.commit('setEditLabelDialog', bool) }
    },
    editShotIndex: {
      get() { return this.$store.state.editShotIndex },
      set(num) { this.$store.commit('setEditShotIndex', num) }
    },
    confirmDialog: {
      get() { return this.$store.state.confirmDialog },
      set(bool) { this.$store.commit('setConfirmDialog', bool) }
    },
  }),

  methods: Object.assign(_mixin.methods, {
    getAnalyses(shots) {
      const analyses = []
      _.forEach(INIT.SHOT_TYPES, shotType => {
        const analysis = _.assign(_.cloneDeep(shotType), {
          name: shotType.type,
          total: _.filter(shots, shot => { return shot.type === shotType.type }).length
        })
        _.forEach(INIT.SHOT_RESULTS, shotResult => {
          const filteredShots = _.filter(shots, shot => { return shot.type === shotType.type && shot.result === shotResult.name })
          const rate = _.round((filteredShots.length / analysis.total) * 100)
          analysis[shotResult.name] = filteredShots.length
          analysis[shotResult.name + ' %'] = rate ? rate : analysis.total ? 0 : '-'
        })
        analyses.push(analysis)
      })
      return analyses
    },
    getTableItem(analyses, name) {
      const item = {name: name}
      _.forEach(INIT.SHOT_TYPES, shotType => {
        item[shotType.type] = _.find(analyses, ['name', shotType.type])[name]
      })
      return item
    },
  }),
})
