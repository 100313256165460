<template>
  <v-dialog
    v-model="editShotDialog"
    content-class="edit-shot-dialog"
    hide-overlay
    persistent
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-select
          :items="shotTypes"
          label="種類"
          item-text="type"
          item-value="type"
          v-model="shotType"
        ></v-select>
        <v-select
          :items="shotResults"
          label="結果"
          :item-text="'label.'+ lang"
          item-value="name"
          v-model="shotResult"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="remove()"
          color="warning"
        >{{ $t('dialog.delete') }}</v-btn>
        <v-spacer />
        <v-btn
          text
          @click="editShotIndex = null"
          color="secondary"
        >{{ $t('dialog.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import INIT from '../init.js'
  export default {
    data() {
      return {
        shotTypes: INIT.SHOT_TYPES,
        shotResults: INIT.SHOT_RESULTS,
      };
    },
    computed: {
      editShotDialog: {
        get() { return this.editShotIndex === 0 || this.editShotIndex },
        set(bool) { this.editShotIndex = bool ? this.editShotIndex : null }
      },
      shots() {
        return _.cloneDeep(this.game.shots)
      },
      shotType: {
        get() { return this.editShotDialog ? this.game.shots[this.editShotIndex].type : '-' },
        set(str) {
          this.shots[this.editShotIndex].type = str
          this.game = _.assign(this.game, {shots: this.shots})
        }
      },
      shotResult: {
        get() { return this.editShotDialog ? this.game.shots[this.editShotIndex].result : '-' },
        set(str) {
          this.shots[this.editShotIndex].result = str
          this.game = _.assign(this.game, {shots: this.shots})
        }
      },
    },
    methods: {
      remove() {
        _.pullAt(this.shots, [this.editShotIndex])
        this.game = _.assign(this.game, {shots: this.shots})
        this.editShotIndex = null
      },
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep .edit-shot-dialog {
    position: relative;
    top: -180px;
  }
</style>
