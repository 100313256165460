<template>

  <div id="table">
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-sort
      mobile-breakpoint="300"
    ></v-data-table>
  </div>

</template>

<script>
  import INIT from '../init.js'
  export default {
    props: ['showAll'],
    data() {
      return {
        items: [],
        headers: [],
      };
    },
		watch: {
      game: {
        handler() {
          this.items = this.getItems()
          this.headers = this.getHeaders()
        },
        immediate: true
      }
		},
    methods: {
      getHeaders() {
        const headers = [{
          text: '',
          align: 'center',
          value: 'name'
        }]
        _.forEach(INIT.SHOT_TYPES, shotType => {
          headers.push({text: shotType.type, value: shotType.type, align: 'center'})
        })
        return headers
      },
      getItems() {
        if (this.showAll) {
          return _.map(['total', 'win %', 'err %', 'cont', 'win', 'err'], name => { return this.getTableItem(this.analyses, name) })
        } else {
          return _.map(['cont', 'win', 'err'], name => { return this.getTableItem(this.analyses, name) })
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  #table {
    background-color: #000;
    .v-data-table {
      ::v-deep th,
      ::v-deep td {
        font-size: 1rem;
        padding: 0;
      }
      ::v-deep tbody td {
        &:nth-child(n+2) {
          font-size: min(5vmin, 20px);
        }
      }
    }
  }
</style>
