import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/_i18n'
import _mixin from '../commons/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'ショット アナリシス',
      appTitle: 'ショット アナリシス | テニスのショットを記録しデータ化するアプリ',
      appDesc: 'テニスのショットを記録しデータ化するアプリです',
    }),
    en: Object.assign(_i18n.en, {
      appName: 'Shots Analysis',
      appTitle: 'Shots Analysis | An app that records and analyzes tennis shots',
      appDesc: 'An app that records and analyzes tennis shots',
    }),
  }
})
