import Vue from 'vue'
import VueRouter from 'vue-router'
import _router from '../commons/_router'
import Setting from '../components/Setting.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: _.concat(_router, [{
    path: '/setting',
    name: 'setting',
    component: Setting
  }])
})

export default router
