<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>構成</v-card-title>
      <v-card-text>
        <GChart
          type="PieChart"
          :data="totalData"
          :options="pieChartOptions"
        ></GChart>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title>ウイニングショット</v-card-title>
      <v-card-text>
        <GChart
          type="ColumnChart"
          :data="winningData"
          :options="chartOptions"
        ></GChart>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title>ミスショット</v-card-title>
      <v-card-text>
        <GChart
          type="ColumnChart"
          :data="losingData"
          :options="chartOptions"
        ></GChart>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title>ショットの安定性</v-card-title>
      <v-card-text>
        <GChart
          type="ColumnChart"
          :data="stableData"
          :options="chartOptions"
        ></GChart>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import INIT from '../init.js'
  import { GChart } from 'vue-google-charts';
  export default {
    data() {
      return {
        pieChartOptions: {
          height: 250,
          chartArea: {
            width: '100%',
            height: '80%'
          },
          pieSliceTextStyle: {
            fontSize: 16
          },
          legend: {
            position: 'labeled',
            textStyle: {
              fontSize: 14,
              color: this.isThemeDark ? '#CCC' : '#333'
            }
          },
          backgroundColor: 'transparent'
        },
        chartOptions: {
          legend: {
            position: 'none',
          },
          annotations: {
            textStyle: {
              fontSize: 16
            }
          },
          hAxis: {
            textStyle: {
              fontSize: 14,
              color: this.isThemeDark ? '#CCC' : '#333'
            }
          },
          vAxis: {
            textPosition: 'none'
          },
          chartArea: {
            width: '100%',
            height: '80%',
            top: '5%'
          },
          backgroundColor: 'transparent',
        },
      };
    },
    computed: {
      // shots() { return this.game.shots },
      filteredShotTypes() {
        return _.filter(INIT.SHOT_TYPES, shotType => { return shotType.type !== 'FO' })
      },
      totalData() {
        const totalNums = _.map(INIT.SHOT_TYPES, shotType => {
          return [shotType.type, _.find(this.analyses, ['name', shotType.type]).total]
        })
        return _.concat([['Shot Type', 'Number of times']], totalNums)
      },
      winningData() {
        const shotNums = _.map(this.filteredShotTypes, shotType => {
          const value = _.find(this.analyses, ['name', shotType.type])['win']
          return [shotType.type, value, value, shotType.color]
        })
        return _.concat([['Shot Type', 'Number of times', {role: 'annotation'}, { role: 'style' }]], shotNums)
      },
      losingData() {
        const shotNums = _.map(this.filteredShotTypes, shotType => {
          const value = _.find(this.analyses, ['name', shotType.type])['err']
          return [shotType.type, value, value, shotType.color]
        })
        return _.concat([['Shot Type', 'Number of times', {role: 'annotation'}, { role: 'style' }]], shotNums)
      },
      stableData() {
        const shotNums = _.map(this.filteredShotTypes, shotType => {
          const value = 100 - _.find(this.analyses, ['name', shotType.type])['err %']
          return [shotType.type, value, value, shotType.color]
        })
        return _.concat([['Shot Type', 'Number of times', {role: 'annotation'}, { role: 'style' }]], shotNums)
      },
    },
    watch: {
      isThemeDark: {
        handler() {
          this.pieChartOptions.legend.textStyle.color = this.isThemeDark ? '#CCC' : '#333'
          this.chartOptions.hAxis.textStyle.color = this.isThemeDark ? '#CCC' : '#333'
        },
        immediate: true
      }
    },
    mounted() {},
    methods: {},
    components: { GChart },

  };
</script>

<style scoped>
</style>

