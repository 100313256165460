<template>
  <fragment>
    
    <TheAppbar :title="$t('title')">
      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </TheAppbar>
    
		<!-- コンテンツ -->
    <v-main class="main-page">
      <v-list>
        <Item
          v-for="(game, index) in games"
          :key="index"
          :index="index"
        ></Item>
      </v-list>
    </v-main>

    <Game />
    <AddButton />
    
  </fragment>
</template>

<script>
  import EX01 from '../assets/ex01.json'
  import TheAppbar from '../commons/components/TheAppbar.vue';
  import Item from './HomeItem.vue';
  import Game from './Game.vue';
  import AddButton from './HomeAddButton.vue';
  
  export default {
    data() {
      return {};
    },
    computed: {},
    mounted() {
      if (! this.games.length) {
        this.games = [EX01]
      }
    },
    watch: {},
    methods: {},
    i18n: {
      messages: {
        ja: {
          title: 'ホーム',
        },
        en: {
          title: 'HOME',
        }
      }
    },
    components: { TheAppbar, Item, Game, AddButton },

  };
</script>

<style lang="scss">
  .theme--light {
    .v-main.main-page {
      background-color: #EEEEEE;
    }
  }
</style>

<style lang="scss" scoped>
  // .v-window-item {
  //   min-height: calc(100vh - 98px); // tabのswipe対応
  // }

  .v-list {
    background-color: transparent;
  }
</style>
